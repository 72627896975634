import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <div>
        <h1>Page Not Found</h1>
        <p>
          <a href="/">Click here to go back to the homepage.</a>
        </p>
      </div>
    </Layout>
  )
}
export default NotFoundPage
